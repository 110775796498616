<template>
  <div class="join">
    <Nav paths="/join" />
    <div class="banner">
      <div class="banner-bg"></div>
    </div>
    <div class="join-range">
      <div class="en-title">Cooperative Alliance</div>
      <div class="chs-title">合作加盟</div>
      <div class="p-title">泰克尼康冻眠市场，是一个赋能平台</div>
      <div class="franchisees" data-aos="fade-up" data-aos-duration="1000">
        <h3>供应商加盟</h3>
        <p>中国近些年进口牛肉持续增长，2019年中国牛肉进口量为165.90万吨，同比增加59.61%，2020年因疫情原因有所下降。市场上的零售进口牛肉，在分级标准上比较混乱，一是美加标准，一是澳洲和牛标准，不过对于普通消费者来说，以上种种标准都是浮云，有没有一个简单的方法来辩别牛肉品质的高低呢，其实我们可以直接看油花颜色和肉色以及解冻后有没有汁液流出。</p>
        <p>为了解决牛肉解冻后没有汁液流出，珠港澳供应链有限公司引进了冻眠技术，通过冻眠处理的新鲜牛肉，在冷冻过程中不会破坏细胞结构，因此不会产生”血水"“血水”中含有大量的营养物质，同时也是影响口感的关键所在，通过冻眠处理牛肉保持了牛肉原有的营养和口感，牛肉如同刚宰杀般的新鲜。</p>
      </div>
      <div class="restaurant" data-aos="fade-up" data-aos-duration="1000">
        <h3>餐厅加盟</h3>
        <p>随着中国经济的不断发展，人民收入水平不断提高，日本料理正雨后春笋般在国内诸多城市诞生，日本料理行业正以25%的速度增长，主要的开店形式有星级酒店寿司餐厅和其它高档寿司餐厅，回专寿司店，自助寿司餐厅，大型商场的寿司专柜等。</p>
        <p>日本料理店的很多食材主要是以进口为主，同时要保持食材的食鲜，很多料理店每天的客人不稳定，所以就造成了很多食材的浪费，食材的保鲜和减少浪费一直是日本料理店的痛点，在日本，90%以上的料理店都采用了冻眠技术解决了这些问题。</p>
        <p>广州鹤睦居酒日本料理店，作为国内第一家引进冻眠技术的日本料理连锁品牌，其神奇的保鲜技术得到了相当的肯定，完全解决了海产等肉质品食材的保鲜和浪费的问题，以其其实熟食的保存、保鲜问题，还提升了产品品质，大大降低了餐厅的运营成本。</p>
      </div>
      <div class="join-img">
        <div class="join-img-item" data-aos="fade-right" data-aos-duration="1000">
          <img :src="require('@/assets/join/sm1.png')">
        </div>
        <div class="join-img-item" data-aos="fade-up" data-aos-duration="1000">
          <img :src="require('@/assets/join/sm2.png')">
        </div>
        <div class="join-img-item" data-aos="fade-left" data-aos-duration="1000">
          <img :src="require('@/assets/join/sm3.png')">
        </div>
      </div>
      <div class="point-img" data-aos="fade-up" data-aos-duration="1000"></div>
    </div>
    <el-backtop :visibility-height="300" :bottom="100" />
  </div>
</template>



<script>
import Nav from '@/components/nav'
export default {
  data () {
    return {

    }
  },
  mounted () {
    window.scrollTo(0, 0);
  },
  components: {
    Nav,
  }
}
</script>

<style lang="less" scoped>
.join {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 1600px;
  min-height: 2000px;
  .banner {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 750px;
    margin: 0 auto;
    .banner-bg {
      width: 1920px;
      height: 750px;
      background: url("../assets/join.png") no-repeat;
      background-size: cover;
      background-position: 50%;
    }
  }
  .join-range {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 1400px;
    margin: 50px auto;
    .en-title {
      font-size: 48px;
      font-weight: bold;
      color: #e6e6e6;
      letter-spacing: 10px;
      text-align: center;
    }
    .chs-title {
      font-size: 36px;
      font-weight: bold;
      color: #000;
      letter-spacing: 5px;
      text-align: center;
      margin-top: -30px;
    }
    .p-title {
      font-size: 22px;
      line-height: 36px;
      color: #000;
      letter-spacing: 3px;
      margin-top: 40px;
      text-align: center;
      margin-bottom: 30px;
    }
    .franchisees {
      margin-bottom: 60px;
    }
    .franchisees,
    .restaurant {
      & h3 {
        font-weight: bold;
        font-size: 22px;
      }
      & p {
        text-indent: 50px;
        font-size: 20px;
      }
      & h3,
      & p {
        color: #000;
        line-height: 36px;
        letter-spacing: 3px;
      }
    }
    .join-img {
      position: relative;
      display: flex;
      justify-content: space-around;
      width: 1400px;
      margin: 50px auto;
      .join-img-item {
        width: 430px;
        height: 516px;
        overflow: hidden;
        border-radius: 5px;
        & img {
          width: 430px;
          height: 516px;
          border-radius: 5px;
          transition: all 1s;
        }
        & img:hover {
          transform: scale(1.25);
        }
      }
    }
    .point-img {
      width: 1400px;
      height: 924px;
      background: url("../assets/join/bg1.png") no-repeat;
      background-size: cover;
    }
  }
}
</style>