<template>
  <div class="navs">
    <div class="navs-logo"></div>
    <div class="navs-item">
      <el-menu :default-active="activeIndex" :router="true" class="el-menu-demo" mode="horizontal" background-color="rgba(255, 255, 255, 0)" text-color="#fff" active-text-color="#fff">
        <!-- <el-menu-item v-for="(item,i) in item_list" :key="i" :index="item.index">{{item.name}}</el-menu-item>
      </el-menu> -->
        <el-menu-item index="/">首页</el-menu-item>
        <el-submenu index="/freeze">
          <template slot="title">关于冻眠</template>
          <el-menu-item index="/freeze" style="color:#000">关于冻眠</el-menu-item>
          <el-menu-item index="/freezeconcept" style="color:#000">冻眠科普</el-menu-item>
        </el-submenu>
        <el-menu-item index="/case">应用案例</el-menu-item>
        <el-menu-item index="/join">加盟合作</el-menu-item>
        <el-menu-item index="/about">关于我们</el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: 'navs',
  props: ['paths'],
  data () {
    return {
      activeIndex: this.paths,
      // activeIndex2: '1'
      // item_list: [
      //   { index: '/', name: '首页', options: ['新闻咨询'] },
      //   { index: '/freeze', name: '关于冻眠' },
      //   { index: '/case', name: '应用案例' },
      //   { index: '/join', name: '加盟合作' },
      //   { index: '/about', name: '关于我们' },
      // ]
    };
  },
}
</script>

<style lang="less" scoped>
.navs {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: 1600px;
  max-width: 1920px;
  height: 100px;
  z-index: 9999;
  margin: 0 auto -100px;
  .navs-logo {
    width: 310px;
    height: 90px;
    background: url('../assets/logo.png') no-repeat;
    background-size: contain;
    margin-top: 5px;
    margin-left: 100px;
  }
  .navs-item {
    position: relative;
    margin: 0 150px;
  }
  .el-menu.el-menu--horizontal {
    border: 0;
  }
  /deep/ .el-submenu__title,
  /deep/ .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
    height: 100px;
    line-height: 100px;
    font-size: 20px;
  }
  .el-menu-demo {
    height: 100px;
  }
  /deep/ .el-menu-item {
    height: 100px;
    line-height: 100px;
    font-size: 20px;
    padding: 0 25px;
  }
  /deep/ .el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
    display: none;
  }
  /deep/ .el-submenu__title:hover,
  .el-menu-item:hover {
    background-color: rgba(255, 255, 255, 0.2) !important;
    border-bottom: 2px solid #fff !important;
  }
  .el-menu--horizontal > .is-active {
    background-color: rgba(255, 255, 255, 0.2) !important;
  }
  .el-menu--horizontal > .el-menu-item.is-active {
    background-color: rgba(255, 255, 255, 0.2) !important;
    border-bottom: 2px solid #fff !important;
  }
}
</style>